import { CSV_HEADERS_STATICS, CSV_HEADERS_TYPES, CurveKey, DataTransform, dateBetweenTypes, GetCurveBody, tsDataRangesObject } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';
import FORMULAS from 'resources/constants/Formulas.js';

import ExcelUtils from './ExcelUtils';
import { Parameters } from './ParameterBuilder';

import { parse } from 'papaparse';

export default(() => {
  const SYMBOL = "Symbol",
        PRODUCT = "Product",
        ROOT = "FuturesRoot",
        FC_PARAMS_PREFIX = "FC Parameters: "

  const PLAIN_PARSED_CURVE_PARAMS = ["range", "lastTypeAmount", "lastType", "fromSelected", "from", "toSelected", "to", "symbolSize", "csvHeaders", "dateFormat", "timeZone", "periodType", "contractSort", "fillBlanks", "isRelative"]

  const DEFAULT_CSV_HEADERS = [{
    name: "Description",
    type: CSV_HEADERS_TYPES.METADATA_KEY
  }, {
    name: ROOT,
    type: CSV_HEADERS_TYPES.METADATA_KEY
  }, {
    name: CSV_HEADERS_STATICS.SYMBOL_COLUMN,
    type: CSV_HEADERS_TYPES.DEFAULT
  }]

  const CSV_HEADER_OPTIONS = [{
    label: "Column",
    value: CSV_HEADERS_STATICS.SYMBOL_COLUMN,
    type: CSV_HEADERS_TYPES.DEFAULT
  }, {
    label: "Group",
    value: CSV_HEADERS_STATICS.GROUP_NAME,
    type: CSV_HEADERS_TYPES.DEFAULT
  }]

  const getKeys = rawKeys => 
    rawKeys.map(key => 
      new CurveKey.Builder()
        .withRoot(key.root)
        .withGroupName(key.groupName)
        .withColumn(key.column)
        .build()
    )

  const getBody = ({ keys, symbolSize, contractSort, dateFormat, fillBlanks, periodType, isRelative, timeZone, startDate, endDate, range, csvHeaders, lastType, lastTypeAmount } = {}) => {
    let builder = new GetCurveBody.Builder()
                    .withKeys(getKeys(keys))
                    .withContractSort(contractSort)
                    .withDateFormat(dateFormat)
                    .withFillBlanks(fillBlanks)
                    .withPeriodType(periodType)
                    .withLimitContracts(symbolSize)
                    .withRelative(isRelative === "true")
                    .withTimezone(timeZone)
    if(range === "TODAY") {
      builder = builder
        .withStartDate(dateBetweenTypes.today)
        .withEndDate(dateBetweenTypes.today)
        .withRange(tsDataRangesObject.between)
    } else if(range === tsDataRangesObject.between) {
      builder = builder
              .withStartDate(startDate === "undefined" || startDate === "Invalid Date" ? undefined : startDate)
              .withEndDate(endDate === "undefined" || endDate === "Invalid Date" ? undefined : endDate)
              .withRange(range)
    } else if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      builder = builder
              .withLastType(lastType)
              .withLastTypeAmount(lastTypeAmount)
              .withRange(range)
    } else {
      builder = builder.withRange(range)
    }
    if(typeof csvHeaders === "object" && csvHeaders?.length > 0) {
      csvHeaders?.forEach(csvHeader => {
        if(csvHeader?.name && csvHeader?.name !== "SYMBOL_VALUES") {
          builder = builder.withCsvHeader(csvHeader)
        }
      })
    } else {
      DEFAULT_CSV_HEADERS.forEach(defaultCsvHeader => {
        builder = builder.withCsvHeader(defaultCsvHeader)
      })
    }
    return builder.build()
  }

  const getForwardCurve = async (params = {}) => async dispatch => {
    const body = getBody(params)
    const rawResponse = await new DataTransform(dispatch(getApiConfig()))
      .getCurveData(body)
      .build()
      .call()

    const textResponse = await rawResponse.text()
    if(!textResponse) {
      return textResponse;
    }
    const parsedResponse = 
        await new Promise((resolve, reject) =>
          parse(textResponse, {
            complete: result => {
              const newResult = result.data
              if(newResult[newResult.length - 1]?.length !== newResult[0]?.length) {
                newResult.pop()
              }
              return resolve(newResult)
            },
            error: err => {
              reject(err)
              console.error(String(err))
            }
          })
        )
    return parsedResponse;
  }

  const paramsToFormula = params => `=${FORMULAS.LOAD_FC}(${params.getGroupedParams().map(item => Object.entries(item).map(([k, v]) => `"${k}=${v}"`).join(", ")).join(", ")},${Object.entries(params.getParameters()).map(([k, v]) => `"${k}=${v}"`).join(", ")})`  

  const isCurveFormula = formula => formula?.includes(FORMULAS.LOAD_FC) || formula?.includes(FORMULAS.LOAD_FC.replaceAll(".", "_"))

  const extractParamsFromFormula = formula => formula.slice((`=${FORMULAS.LOAD_FC}`).length).replace(/\\"/g, '"').slice(2, -2).replaceAll(", ", ",").split(/","(?!")/).map(s => s.replaceAll("\"\"", "\""))

  const validateTable = async ({ item, cellValue, cell, formula, returnItem = false }) => {
    if((!cellValue || String(cellValue).startsWith(FC_PARAMS_PREFIX)) && isCurveFormula(formula)) {
      const parsedFormula = await ExcelUtils.parseFormula({ formula, extractParamsFromFormula })
      const parsedParameters = new Parameters.Builder()
        .withUserParameters(parsedFormula)
        .withGrouping(["groupName", "column", "product", "root"])
        .build();
      const toReturn = {
        parsedParameters,
        formula,
        prefix: FC_PARAMS_PREFIX,
        address: cell.address
      }
      if(returnItem) {
        return toReturn;
      }
      return item.concat(toReturn)
    }
    return item
  }

  return {
    getForwardCurve,
    FC_PARAMS_PREFIX,
    paramsToFormula,
    validateTable,
    PLAIN_PARSED_CURVE_PARAMS,
    DEFAULT_CSV_HEADERS,
    SYMBOL,
    PRODUCT,
    ROOT,
    CSV_HEADER_OPTIONS
  }
})()